.NavbarItems {
    height: 80px;
    background-color: #f11c1c;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
}



.navbar-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
}

.fa-hard-hat {
    margin-left: 0.5rem;
    font-size: 1.6rem;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end;
    margin-right: 2rem;
}

.nav-links {
    color: #fff;
    text-decoration: none;
    padding: 0.5rem 1rem;
}

.nav-links:hover {
    background-color: #f55b5b;
    border-radius: 4px;
    transition: all 0.2s ease-out;
}

.fa-bars {
    color: #fff;
}

.menu-icon {
    display: none;
}

.no-underline {
    text-decoration: none;
}

@media screen and (max-width: 850px)  {
    .NavbarItems {
        position: relative;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active {
        background-color: #f11c1c;;
        left: 0;
        opacity: 1;
        transition: all 0.5 ease;
        z-index: 3;
        padding-left: 0;
    }

    .nav-links {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
    }

    .nav-links:hover {
        background-color: #f55b5b;
        border-radius: 0;
    }

    .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        transform:translateY(80%);
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }
}

@media screen and (orientation: landscape)  {
    .nav-links {
        padding: 14px;
    }
}