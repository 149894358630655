.services-container {
    height: 60vh;
    background-color: lightgray;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.services-header{
    margin: 10px;
    width: 40vw;
    text-transform: uppercase;
    text-align: center;
    z-index: 2;
    color: black;
}

.services-para{
    margin: 10px;
    width: 60vw;
    text-align: center;
    z-index: 2;
    color: black}

.wcu-container{
    margin: auto;
    background-color: #ff6d36;
    width: 800px;
    /* height: 70vh; */
    border-radius: 10px;
}

.wcu-items{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 60px;
    width: 70%;
    margin: auto;
    font-size: 30px;
    text-align: center;
    margin-top: 70px;
}

.wcu-icons{
    margin-bottom: 10px;
    font-size: 55px;
}

.wcu-housing{
    background-color: lightgray;
    padding-bottom: 50px;
}

.wcu-header{
    padding: 30px;
    color: rgb(0, 0, 0);
}


@media screen and (max-width: 850px) {
    .services-header {
        margin: 10px;
        width: 90vw;
        text-transform: uppercase;
        text-align: center;
        font-size: 0.6rem;
    }

    .services-para{
        margin: 10px;
        width: 90vw;
        text-align: center;
        font-size: 0.8rem;
    }

    .wcu-housing{
        padding-bottom: 0px;
    }

    .wcu-container{
        margin: 0;
        width: 100vw;
    }

    .wcu-span{
        font-size: 25px;
    }
}