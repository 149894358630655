.ContactUs-container {
    height: 60vh;
    background-color: lightgray;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.ContactUs-header{
    margin: 10px;
    width: 40vw;
    text-transform: uppercase;
    text-align: center;
    z-index: 2;
    color: black;
}

.ContactUs-para{
    margin: 10px;
    width: 60vw;
    text-align: center;
    z-index: 2;
    color: black;
    margin-bottom: 60px;
}

.ContactUs-contact{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.ContactUs-contactInner1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
}

.ContactUs-contactInner2{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
}

.mb{
    margin-bottom: 10px;
}

.color{
    color:black;
    text-decoration: none;
}

.form-housing{
    background-color: lightgray;
    padding-bottom: 50px;
}

.ContactUs-phone-icon{
    font-size: 60px;
}

@media screen and (max-width: 850px) {
    .ContactUs-header {
        margin: 10px;
        width: 90vw;
        text-transform: uppercase;
        text-align: center;
        font-size: 0.6rem;
    }

    .ContactUs-para{
        margin: 10px;
        width: 90vw;
        text-align: center;
        font-size: 0.8rem;
    }

    .form-housing{
        padding-bottom: 0px;
    }
}