.footer-container{
    height: 65px;
    background-color: black;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footer-link{
    color: #fff;
}